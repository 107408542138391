import React, { useState, useEffect } from 'react';
import { Button, Form } from 'react-bootstrap';
import { DocumentValidity } from "../Pages/Trucks/TruckTypes";
import DateField from './fields/DateField';

interface Props {
  driverId: number;
  document?: DocumentValidity;
  onClose: () => void;
  onSave: (document: DocumentValidity) => void;
}

const DOCUMENT_TYPES = [
  { id: '95 kodo galiojimo data', name: '95 kodo galiojimo data' },
  { id: 'A-1 forma', name: 'A-1 forma' },
  { id: 'ADR', name: 'ADR' },
  { id: 'Asmens dokumentas - Id kortelė', name: 'Asmens dokumentas - Id kortelė' },
  { id: 'Asmens dokumentas - pasas', name: 'Asmens dokumentas - pasas' },
  { id: 'D viza', name: 'D viza' },
  { id: 'ESDK', name: 'ESDK' },
  { id: 'Kelionių draudimo polisas', name: 'Kelionių draudimo polisas' },
  { id: 'LLG', name: 'LLG' },
  { id: 'Rožinis lapas', name: 'Rožinis lapas' },
  { id: 'Vairuotojo pažymėjimas', name: 'Vairuotojo pažymėjimas' },
  { id: 'Vairuotojo skaitmeninė kortelė', name: 'Vairuotojo skaitmeninė kortelė' },
];

export default function DocumentValidityPopup({ driverId, document, onClose, onSave }: Props) {
  const [type, setType] = useState(document?.type || DOCUMENT_TYPES[0].id);
  const [validUntil, setValidUntil] = useState(document?.validUntil || '');
  const [documentNumber, setDocumentNumber] = useState(document?.documentNumber || '');
  const [comment, setComment] = useState(document?.comment || '');
  const isEditMode = !!document;

  useEffect(() => {
    if (document) {
      setType(document.type);
      setValidUntil(document.validUntil);
      setDocumentNumber(document.documentNumber);
      setComment(document.comment);
    }
  }, [document]);

  const handleSave = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();

    if (comment.length > 600) {
      return;
    }

    const documentData: DocumentValidity = {
      id: document?.id || Date.now(),
      type,
      validUntil,
      documentNumber,
      comment
    };

    onSave(documentData);
  };

  const handleDateChange = (date: string) => {
    setValidUntil(date);
  };

  const isDisabled = !type || !validUntil;

  return (
    <div
      className="px-4 pb-2"
      onMouseDown={(e: React.MouseEvent) => e.stopPropagation()}
    >
      <Form>
        <Form.Group className="mb-3">
          <Form.Label>Dokumento tipas</Form.Label>
          <Form.Control
            as="select"
            value={type}
            onChange={(e) => {
              setType(e.target.value);
            }}
          >
            {DOCUMENT_TYPES.map((docType) => (
              <option key={`type-${docType.id}`} value={docType.id}>
                {docType.name}
              </option>
            ))}
          </Form.Control>
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Galiojimo data</Form.Label>
          <div className="tw3-relative">
            <DateField
              value={validUntil}
              setValue={handleDateChange}
              inPopup={true}
            />
          </div>
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Dokumento numeris</Form.Label>
          <Form.Control
            value={documentNumber}
            onChange={(e) => setDocumentNumber(e.target.value)}
          />
        </Form.Group>

        <Form.Group className="mb-3">
          <div className="flex justify-between items-center">
            <Form.Label className="m-0">Komentaras</Form.Label>
            <small className={`${comment.length > 600 ? 'text-red-500' : 'text-gray-500'}`}>
              {comment.length}/600
            </small>
          </div>
          <Form.Control
            as="textarea"
            rows={3}
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            isInvalid={comment.length > 600}
          />
          {comment.length > 600 && (
            <Form.Control.Feedback type="invalid">
              Komentaras negali būti ilgesnis nei 600 simbolių
            </Form.Control.Feedback>
          )}
        </Form.Group>

        <div className="mt-4 flex justify-end gap-2">
          <Button
            variant="primary"
            onClick={handleSave}
            disabled={isDisabled}
          >
            {isEditMode ? 'Atnaujinti' : 'Pridėti'}
          </Button>
          <Button
            variant="secondary"
            onClick={onClose}
          >
            Atšaukti
          </Button>
        </div>
      </Form>
    </div>
  );
}