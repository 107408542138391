import React, { Fragment } from 'react'
import { MenuFolder, MenuSpacer } from '@newageerp/ui.menu.base.menu'
import MenuItemTrips from "../items/MenuItemTrips" 
import MenuItemTripsReports from "../items/MenuItemTripsReports" 
import MenuItemInvoicesTrips from "../items/MenuItemInvoicesTrips" 
import MenuItemDrivers from "../items/MenuItemDrivers" 
import MenuItemTrucksLocations from "../items/MenuItemTrucksLocations" 
import MenuItemTripsReportsKm from "../items/MenuItemTripsReportsKm" 
import MenuItemTripsReportsFrigo from "../items/MenuItemTripsReportsFrigo" 
import MenuItemReportsAdblue from "../items/MenuItemReportsAdblue" 
import MenuItemReportsFuel from "../items/MenuItemReportsFuel" 
import MenuItemTripsReportsFuelKm from "../items/MenuItemTripsReportsFuelKm" 
import MenuItemClientMain from "../items/MenuItemClientMain" 
import MenuItemDriverReports from '../MenuItemDriverReports'
// import MenuItemCandidates from '../items/MenuItemCandidates'


export default function MenuFolderPardavimai171() {
    return (
        <Fragment>
            <MenuFolder
                item={{
                    children: 'Pardavimai',
                    icon: 'file-invoice-dollar'
                }}
                menuFolderId='MenuFolderPardavimai171'
            >

                <MenuItemTrips forceSkipIcon={true}/>
                <MenuItemTripsReports forceSkipIcon={true}/>
                <MenuSpacer />
                <MenuItemInvoicesTrips forceSkipIcon={true}/>
                <MenuItemDrivers forceSkipIcon={true}/>
                {/* <MenuItemCandidates forceSkipIcon={true}/> */}
                <MenuItemTrucksLocations forceSkipIcon={true}/>
                <MenuSpacer />
                <MenuItemDriverReports forceSkipIcon={true}/>
                <MenuItemTripsReportsKm forceSkipIcon={true}/>
                <MenuItemTripsReportsFrigo forceSkipIcon={true}/>
                <MenuItemReportsAdblue forceSkipIcon={true}/>
                <MenuItemReportsFuel forceSkipIcon={true}/>
                <MenuItemTripsReportsFuelKm forceSkipIcon={true}/>
                <MenuSpacer />
                <MenuItemClientMain forceSkipIcon={true}/>
            </MenuFolder>
        </Fragment>
    )
}
