import React, { Fragment, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import ApiRest from "../../../service/ApiRest";
import { WhiteCard } from "@newageerp/ui.cards.base.white-card";
import { UI } from "@newageerp/nae-react-ui";
import { Col, Form, Row } from "react-bootstrap";
import DateField from "../../Forms/fields/DateField";
import moment from "moment";
import { useRecoilState } from "recoil";
import { UserSpaceWrapperToolbarState } from "../../../_generated/layout/toolbar/UserSpaceWrapperToolbar";
import { Table, Th, Td } from '@newageerp/ui.table.base.table';
import TableWithPagingV2 from "../../TableWithPagingV2";
import { TwTextAlignment } from "@newageerp/ui.styles.tailwind";
// @ts-ignore
import { Multiselect } from 'multiselect-react-dropdown';
import { Driver, DriverHistory, DocumentValidity } from "./TruckTypes";
import { atom } from "recoil";

const EXCEL_URI = 'data:application/vnd.ms-excel;base64,';
const EXCEL_TEMPLATE = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><meta charset="UTF-8"><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body>{table}</body></html>';


type DocumentValidityType = 'has' | 'hasnt';

interface SearchElement {
    hireDateFrom?: string;
    hireDateTo?: string;
    documentsValidFrom?: string;
    documentsValidTo?: string;
    terminationDateFrom?: string;
    terminationDateTo?: string;
    employmentContractNumber?: string;
    documentType?: string;
    documentValidity?: DocumentValidityType;
    documentValidityDate?: string;
    employmentStatus?: string;
    address?: string;
}

interface FilterState {
    searchElement: {
        hireDateFrom?: string;
        hireDateTo?: string;
        documentsValidFrom?: string;
        documentsValidTo?: string;
        terminationDateFrom?: string;
        terminationDateTo?: string;
        employmentContractNumber?: string;
        documentType?: string;
        documentValidity?: 'has' | 'hasnt';
        documentValidityDate?: string;
        employmentStatus?: string;
        address?: string;
    };
    selectedCountries: CountryOption[];
    search: string;
    showFilter: boolean;
}

const filterStateAtom = atom<FilterState>({
    key: 'driverReportFilterState',
    default: {
        searchElement: {},
        selectedCountries: [],
        search: '',
        showFilter: false
    }
});

interface CountryOption {
    id: string;
    name: string;
}

interface DocumentTypeOption {
    id: string;
    name: string;
}

export default function DriverReportsPage() {
    const [, setToolbarTitle] = useRecoilState(UserSpaceWrapperToolbarState);
    const [filterState, setFilterState] = useRecoilState(filterStateAtom);
    const history = useHistory();

    const base64 = (s: string) => window.btoa(unescape(encodeURIComponent(s)));
    const doFormat = (s: string) => {
        const context: Record<string, string | undefined> = {
            worksheet: 'Worksheet',
            table: window.document.getElementById("table-to-export")?.outerHTML,
        };
        return s.replace(/{(\w+)}/g, (_m: string, p: string) => context[p] || '');
    };

    const doDownload = () => {
        const element = window.document.createElement('a');
        element.href = EXCEL_URI + base64(doFormat(EXCEL_TEMPLATE));
        element.download = 'employees-report.xls';
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
    };


    const [searchElement, setSearchElement] = useState(filterState.searchElement);
    const [drivers, setDrivers] = useState<Driver[]>([]);
    const [selectedCountries, setSelectedCountries] = useState<CountryOption[]>(filterState.selectedCountries);
    const [showFilter, setShowFilter] = useState(filterState.showFilter);
    const [search, setSearch] = useState(filterState.search);
    const [dataToRender, setDataToRender] = useState<Driver[]>([]);
    const [documentTypes, setDocumentTypes] = useState<DocumentTypeOption[]>([]);
    const [readyToLoad, setReadyToLoad] = useState(false);
    const [activePage, setActivePage] = useState(1);

    const appendSearch = (key: keyof SearchElement, val: string) => {
        setSearchElement(prev => ({ ...prev, [key]: val }));
    };

    useEffect(() => {
        setFilterState({
            searchElement,
            selectedCountries,
            search,
            showFilter
        });
    }, [searchElement, selectedCountries, search, showFilter]);

    useEffect(() => {
        ApiRest.getElements('document-types')
            .then(res => {
                if (res) {
                    setDocumentTypes(res.map((type: any) => ({
                        id: type.document_type,
                        name: type.document_type
                    })));
                }
            })
            .catch(error => {
                console.error('Error fetching document types:', error);
            });
    }, []);

    const getListData = () => {
        const extraData: Record<string, any> = {};

        if (searchElement.documentType) {
            extraData.documentType = searchElement.documentType;
            if (searchElement.documentValidity) {
                extraData.documentValidity = searchElement.documentValidity;
                if (searchElement.documentValidityDate) {
                    if (searchElement.documentValidity === 'has') {
                        extraData.documentValidityDateBefore = searchElement.documentValidityDate;
                    } else if (searchElement.documentValidity === 'hasnt') {
                        extraData.documentValidityDateAfter = searchElement.documentValidityDate;
                    }
                }
            }
        }

        ApiRest.getElements('employee', extraData)
            .then(res => {
                if (res) {
                    setDrivers(res);
                }
            })
            .catch(error => {
                console.error('Error fetching employees:', error);
            });
    };

    useEffect(getListData, [searchElement.documentType, searchElement.documentValidity, searchElement.documentValidityDate]);

    const countryOptions = useMemo(() => {
        const countries = drivers
            .map(d => d.country)
            .filter((country): country is string => Boolean(country));
        return Array.from(new Set(countries)).map(country => ({ id: country, name: country }));
    }, [drivers]);

    const documentTypeOptions = documentTypes;

    const employmentStatusOptions = [
        { id: "all", name: "Visi" },
        { id: "active", name: "Aktyvus" },
        { id: "inactive", name: "Neaktyvus" }
    ];

    const toggleFilter = () => setShowFilter(!showFilter);

    const filterData = () => {
        if (readyToLoad) {
            let _data = drivers;

            // Text search
            if (search) {
                const searchLower = search.toLowerCase();
                _data = _data.filter(item => {
                    const fullName = `${item.firstName} ${item.lastName}`.toLowerCase();
                    return fullName.includes(searchLower) ||
                        item.personalCode?.toLowerCase().includes(searchLower) ||
                        item.history?.some(hist => hist.change.toLowerCase().includes(searchLower));
                });
            }

            // Country filter
            if (selectedCountries.length > 0) {
                const selectedCountryNames = selectedCountries.map(c => c.name);
                _data = _data.filter(item => selectedCountryNames.includes(item.country));
            }

            // Employment status filter
            if (searchElement.employmentStatus) {
                _data = _data.filter(driver => {
                    switch (searchElement.employmentStatus) {
                        case 'active':
                            return !driver.terminationDate || moment(driver.terminationDate).isAfter(moment());
                        case 'inactive':
                            return driver.terminationDate && moment(driver.terminationDate).isSameOrBefore(moment());
                        case 'all':
                        default:
                            return true;
                    }
                });
            }

            // Date filters
            if (searchElement.hireDateFrom) {
                _data = _data.filter(item => moment(item.hireDate) >= moment(searchElement.hireDateFrom));
            }

            if (searchElement.terminationDateFrom) {
                _data = _data.filter(item =>
                    item.terminationDate && moment(item.terminationDate) >= moment(searchElement.terminationDateFrom)
                );
            }

            if (searchElement.documentType && searchElement.documentValidity && searchElement.documentValidityDate) {
                _data = _data.filter(driver => {
                    const document = driver.documents?.find(doc => doc.type === searchElement.documentType);
                    
                    if (searchElement.documentValidity === 'has') {
                        return document && moment(document.validUntil).isSameOrBefore(moment(searchElement.documentValidityDate));
                    } else if (searchElement.documentValidity === 'hasnt') {
                        return !document || moment(document.validUntil).isSameOrAfter(moment(searchElement.documentValidityDate));
                    }
                    return true;
                });
            }

            // Address filter
            if (searchElement.address) {
                const addressSearch = searchElement.address.toLowerCase();
                _data = _data.filter(driver =>
                    driver.addresses?.some(addr => {
                        const fullAddress = `${addr.city}${addr.address ? `, ${addr.address}` : ''}`.toLowerCase();
                        return fullAddress.includes(addressSearch);
                    })
                );
            }

            setDataToRender(_data);
        }
    };

    useEffect(filterData, [
        search,
        activePage,
        drivers,
        readyToLoad,
        searchElement.documentType,
        searchElement.documentValidity,
        searchElement.documentValidityDate,
        selectedCountries,
        searchElement.employmentStatus,
        searchElement.hireDateFrom,
        searchElement.terminationDateFrom,
        searchElement.address,
    ]);

    useEffect(() => {
        setToolbarTitle('Darbuotojų ataskaita');
        setReadyToLoad(true);
    }, [setToolbarTitle]);

    const goTo = (item: Driver) => {
        history.push('/employee/' + item.id);
    };

    const renderHistoryBadge = (driver: Driver) => {
        const matchingHistory = driver.history?.filter(hist =>
            hist.change.toLowerCase().includes(search.toLowerCase())
        );

        if (search && matchingHistory && matchingHistory.length > 0) {
            return (
                <div className="ml-2 inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-blue-100 text-blue-800">
                    {matchingHistory.length} komentarai
                </div>
            );
        }
        return null;
    };

    const getDocumentDateLabel = () => {
        if (searchElement.documentValidity === 'has') {
            return 'Dokumento galiojimo data iki';
        }
        if (searchElement.documentValidity === 'hasnt') {
            return 'Dokumento Negaliojimo data nuo';
        }
        return 'Dokumento galiojimo data iki';
    };

    const columnClasses = {
        name: "max-w-[200px] truncate",
        country: "max-w-[100px] truncate",
        personalCode: "max-w-[120px] truncate",
        dates: "w-[100px]",
        address: "max-w-[250px] truncate"
    };

    return (
        <div>
            <WhiteCard compact={true}>
                <div className="flex gap-2 px-4">
                    <UI.Form.Input
                        placeholder="Paieška..."
                        className="w-full"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                    />
                    <UI.Buttons.Button
                        bgColor={UI.Buttons.ButtonBgColor.nsecondary}
                        onClick={toggleFilter}
                    >
                        Filtras
                    </UI.Buttons.Button>
                    <UI.Buttons.Button
                        bgColor={UI.Buttons.ButtonBgColor.green}
                        brightness={700}
                        onClick={doDownload}
                    >
                        Xlsx
                    </UI.Buttons.Button>
                </div>

                {showFilter && (
                    <div className="bg-blue-50 rounded-md px-4 mt-4">
                        <Row>
                            <Col sm={4}>
                                <Form.Group>
                                    <Form.Label>Dokumentas</Form.Label>
                                    <Form.Control
                                        as="select"
                                        value={searchElement.documentType || ''}
                                        onChange={(e) => appendSearch('documentType', e.target.value)}
                                    >
                                        <option value="">Pasirinkti...</option>
                                        {documentTypeOptions.map((option) => (
                                            <option key={`doc-${option.id}`} value={option.id}>
                                                {option.name}
                                            </option>
                                        ))}
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                            <Col sm={4}>
                                <Form.Group>
                                    <Form.Label>Dokumentas (turi/neturi)</Form.Label>
                                    <Form.Control
                                        as="select"
                                        value={searchElement.documentValidity || '-'}
                                        onChange={(e) => {
                                            setSearchElement(prev => ({
                                                ...prev,
                                                documentValidity: e.target.value as DocumentValidityType,
                                                documentValidityDate: ''
                                            }));
                                        }}
                                        disabled={!searchElement.documentType}
                                    >
                                        <option disabled>-</option>
                                        <option value="has">Turi</option>
                                        <option value="hasnt">Neturi</option>
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                            <Col sm={4}>
                                <Form.Group>
                                    <Form.Label>{getDocumentDateLabel()}</Form.Label>
                                    <DateField
                                        value={searchElement.documentValidityDate || ''}
                                        setValue={(val) => appendSearch('documentValidityDate', val)}
                                        disabled={!searchElement.documentValidity}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        
                        <Row>
                            <Col sm={4}>
                                <Form.Group>
                                    <Form.Label>Įdarbinimo statusas</Form.Label>
                                    <Form.Control
                                        as="select"
                                        value={searchElement.employmentStatus || 'active'}
                                        onChange={(e) => appendSearch('employmentStatus', e.target.value)}
                                    >
                                        {employmentStatusOptions.map((option) => (
                                            <option key={`status-${option.id}`} value={option.id}>
                                                {option.name}
                                            </option>
                                        ))}
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                            <Col sm={4}>
                                <Form.Group>
                                    <Form.Label>Įdarbinimo data nuo</Form.Label>
                                    <DateField
                                        value={searchElement.hireDateFrom || ''}
                                        setValue={(val) => appendSearch('hireDateFrom', val)}
                                    />
                                </Form.Group>
                            </Col>
                            <Col sm={4}>
                                <Form.Group>
                                    <Form.Label>Atleidimo data nuo</Form.Label>
                                    <DateField
                                        value={searchElement.terminationDateFrom || ''}
                                        setValue={(val) => appendSearch('terminationDateFrom', val)}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row>
                            <Col sm={6}>
                                <Form.Group>
                                    <Form.Label>Pilietybė</Form.Label>
                                    <Multiselect
                                        options={countryOptions}
                                        displayValue="name"
                                        placeholder="Pasirinkti..."
                                        onSelect={(selectedList: CountryOption[]) => setSelectedCountries(selectedList)}
                                        onRemove={(selectedList: CountryOption[]) => setSelectedCountries(selectedList)}
                                        selectedValues={selectedCountries}
                                    />
                                </Form.Group>
                            </Col>
                            <Col sm={6}>
                                <Form.Group>
                                    <Form.Label>Adresas</Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={searchElement.address || ''}
                                        onChange={(e) => appendSearch('address', e.target.value)}
                                        placeholder="Ieškoti pagal adresą..."
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                    </div>
                )}


                <div className="my-3 pl-3 text-sm text-gray-600">
                    Rodoma {dataToRender.length} iš {drivers.length}
                </div>
                <div>
                    <TableWithPagingV2
                        pageSize={100}
                        activePage={activePage}
                        setActivePage={setActivePage}
                        dataToRender={dataToRender}
                        head={
                            <tr className="border-t">
                                <Th className={columnClasses.name}>Vardas Pavardė</Th>
                                <Th className={columnClasses.country}>Pilietybė</Th>
                                <Th className={columnClasses.personalCode}>Asmens kodas</Th>
                                <Th className={columnClasses.dates}>Įdarbinimo data</Th>
                                <Th className={columnClasses.dates}>Atleidimo data</Th>
                                <Th className={columnClasses.address}>Adresas</Th>
                                {searchElement.documentType && (
                                    <Th className={columnClasses.dates}>Dokumento galiojimo data</Th>
                                )}
                            </tr>
                        }
                        renderItem={(item: Driver) => (
                            <tr key={`list-row-${item.id}`} className="hover:bg-gray-50">
                                <Td className={columnClasses.name}>
                                    <div className="flex items-center">
                                        <button
                                            onClick={() => goTo(item)}
                                            className="text-left text-primary hover:underline truncate"
                                            title={`${item.firstName} ${item.lastName}`}
                                        >
                                            {item.firstName} {item.lastName}
                                        </button>
                                        {renderHistoryBadge(item)}
                                    </div>
                                </Td>
                                <Td className={columnClasses.country} title={item.country}>
                                    {item.country}
                                </Td>
                                <Td className={columnClasses.personalCode} title={item.personalCode}>
                                    {item.personalCode}
                                </Td>
                                <Td className={columnClasses.dates}>
                                    {item.hireDate ? moment(item.hireDate).format('YYYY-MM-DD') : ''}
                                </Td>
                                <Td className={columnClasses.dates}>
                                    {item.terminationDate ? moment(item.terminationDate).format('YYYY-MM-DD') : ''}
                                </Td>
                                <Td className={columnClasses.address}>
                                    <div className="truncate" title={item.addresses?.[0] ? `${item.addresses[0].city}${item.addresses[0].address ? `, ${item.addresses[0].address}` : ''}` : ''}>
                                        {item.addresses?.[0] ?
                                            `${item.addresses[0].city}${item.addresses[0].address ? `, ${item.addresses[0].address}` : ''}`
                                            : ''}
                                    </div>
                                </Td>
                                {searchElement.documentType && (
                                    <Td className={columnClasses.dates}>
                                        {item.documents?.find(doc => doc.type === searchElement.documentType)?.validUntil ?
                                            moment(item.documents?.find(doc => doc.type === searchElement.documentType)?.validUntil).format('YYYY-MM-DD')
                                            : '-'}
                                    </Td>
                                )}
                            </tr>
                        )}
                    />
                </div>
            </WhiteCard>
        </div>
    );
}